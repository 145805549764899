<template>
    <div class="modal-select-coupon">
        <transition name="slide-up">
            <div v-show="show" class="coupons" @click="close">
                <div class="head flex-row flex-between">
                    <span v-html="$translate('SELECT_COUPON')" />
                </div>
                <div class="body">
                    <ul class="coupon-list">
                        <li class="coupon" v-for="coupon in coupons" :key="coupon.id" @click="close(coupon)">
                            <i
                                class="material-icons m-r-12"
                                :class="{
                                    checked: coupon.checked,
                                    unchecked: !coupon.checked,
                                }"
                            >
                                {{ coupon.checked ? 'radio_button_checked' : 'radio_button_unchecked' }}
                            </i>
                            <div class="detail flex">
                                <span class="name">{{ coupon.discount.name }}</span>
                                <span v-if="coupon.discount.amount > 0" class="amount"
                                    >{{ coupon.discount.amount }}원 할인</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ModalSelectCoupon',
    props: ['options'],
    data: () => ({
        show: false,
        coupons: [],
    }),
    mounted() {
        setTimeout(() => {
            this.show = true
        })
        this.initCoupons()
    },
    methods: {
        initCoupons() {
            const emptyCoupon = {
                id: null,
                enabled: true,
                checked: this.options.selectedCouponId ? false : true,
                discount: {
                    name: '선택 안함',
                },
            }

            this.coupons.push(emptyCoupon)
            const dus = this.options.discountUsers
            if (dus) {
                const coupons = dus
                    .filter(du => du.enabled)
                    .map(du => {
                        return {
                            id: du.id,
                            checked: du.id === this.options.selectedCouponId,
                            discount: du.discount,
                        }
                    })
                this.coupons = this.coupons.concat(coupons)
            }
        },
        close(coupon) {
            this.coupons.forEach(c => {
                c.checked = c.id === coupon.id
            })

            setTimeout(() => {
                this.show = false
            }, 500)
            setTimeout(() => {
                this.$emit('close', coupon)
            }, 1000)
        },
    },
}
</script>
